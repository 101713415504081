import iconContrast from './../../../src/contrast.svg';

export default function initA11y() {
  const siteHeaderToprow = document.querySelector(".site-header-toprow");
  const a11yMenu = document.createElement("div");
  const btnToggleHighContrast = document.createElement("button");

  a11yMenu.dataset.a11yMenu = "";

  btnToggleHighContrast.setAttribute("aria-label", "Umschalter Hoher Kontrast");
  btnToggleHighContrast.innerText = "Hoher Kontrast";
  btnToggleHighContrast.insertAdjacentHTML('afterBegin', iconContrast);
  btnToggleHighContrast.addEventListener("click", toggleHighContrast);

  a11yMenu.append(btnToggleHighContrast);
  siteHeaderToprow.append(a11yMenu);
}

function toggleHighContrast() {
  const savedValue = window.localStorage.getItem("high-contrast");
  console.log(savedValue);
  document.documentElement.classList.toggle("high-contrast");
  if (savedValue !== "true") {
    window.localStorage.setItem("high-contrast", "true");
  } else {
    window.localStorage.removeItem("high-contrast");
  }
}
