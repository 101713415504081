import "./components/mouseOrbit";
import initA11y from "./components/a11y";
import * as basicLightbox from "basiclightbox/src/scripts/main";
import { tns } from "tiny-slider/src/tiny-slider";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from "body-scroll-lock";
import leftIcon from "../../web/assets/images/left.svg";
import rightIcon from "../../web/assets/images/right.svg";

Array.from(document.querySelectorAll("[data-lightbox]")).forEach(element => {
  element.onclick = e => {
    e.preventDefault();
    basicLightbox.create(`<img src="${element.href}">`).show();
  };
});

function initSliders() {
  const carousels = document.querySelectorAll(".block--carousel-slides");
  const sliders = document.querySelectorAll(".block--slider-slides");
  const relatedPagesCollections = document.querySelectorAll(
    ".related-pages-collection .cards"
  );

  if (carousels) {
    carousels.forEach(carousel => {
      tns({
        container: carousel,
        controlsText: [leftIcon, rightIcon],
        items: 1,
        slideBy: "page",
        loop: false,
        preventScrollOnTouch: "auto",
        responsive: {
          640: {
            gutter: 20,
            items: 2
          },
          1024: {
            gutter: 30,
            items: 3
          }
        }
      });
    });
  }
  if (sliders) {
    sliders.forEach(slider => {
      tns({
        container: slider,
        controlsText: [leftIcon, rightIcon],
        items: 1,
        loop: false,
        preventScrollOnTouch: "auto"
      });
    });
  }

  relatedPagesCollections.forEach(collection => {
    tns({
      container: collection,
      controlsText: [leftIcon, rightIcon],
      items: 1,
      slideBy: 1,
      controls: true,
      loop: false,
      preventScrollOnTouch: "auto",
      onInit: insertIndexes.bind(this),
      responsive: {
        640: {
          gutter: 30,
          items: 2,
          slideBy: 2
        },
        1024: {
          items: 4,
          slideBy: 4
        }
      }
    });

    function insertIndexes(target) {
      Array.from(target.navContainer.children).forEach(el => {
        el.innerText = parseInt(el.dataset.nav) + 1;
      });
    }
  });
}

function initMobileMenu() {
  const openMenu = document.querySelector(".open-menu");
  const closeMenu = document.querySelector(".close-menu");
  const mainNavigation = document.querySelector(".main-navigation");

  openMenu.addEventListener("click", _ => {
    document.body.classList.add("menu-open");
    disableBodyScroll(mainNavigation);
  });

  closeMenu.addEventListener("click", _ => {
    document.body.classList.remove("menu-open");
    enableBodyScroll(mainNavigation);
  });
}

window.addEventListener("DOMContentLoaded", () => {
  initMobileMenu();
  initSliders();
  initA11y();
});
